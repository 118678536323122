@import "../../../../../../lib/uio1/profile/css/util.less";
@import "../../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

html,
body {
  font-family: Msreference;
}
h1 {
  font-size: 28px;
  font-size: 2.8rem;
}
h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
h3 {
  font-size: 20px;
  font-size: 2rem;
}
h4 {
  font-size: 18px;
  font-size: 1.8rem;
}
.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  &.header-search-collapsable .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    color: @color-dark;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
      color: @color-light;
    }
  }

  .header-search-expand {
    background: url(../uio1/profile/images/responsive/search-icon.svg)
      9px 9px no-repeat;
    background-size: 20px auto;
    &:focus,
    &:hover {
      background: url(../uio1/profile/images/responsive/search-icon-active.svg)
        9px 9px no-repeat;
      background-size: 20px auto;
    }
  }
  
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: @color-light;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 190px;
      .head-menu {
        right: 120px;
        .language {
          display: none;
        }
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        a {
          position: absolute;
          color: @color-dark;
          top: 25px;
          font-size: 30px;
          font-size: 3rem;
          img {
            max-width: 65%;
          }
          .logo-tekst {
            margin-left: 20px;
            margin-top: 55px;
            position: absolute;
            min-width: 365px;
            span {
              font-size: 22px;
              font-size: 2.2rem;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-secondary;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
    &::before {
      color: @color-secondary--dark;
    }
  }
  a {
    &:hover,
    &:focus {
      color: @color-dark;
    }
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-light;
      border-top-color: @color-secondary--dark !important;
      &:hover,
      &:focus {
        color: @color-light;
      }
    }
    &.vrtx-after-marked {
      border-top-color: @color-secondary--dark !important;
    }
  }
}

table th {
  font-weight: bold;
}

blockquote {
  border-left: 4px solid @color-primary;
}

.vrtx-social-components .vrtx-email-friend,
.vrtx-social-components .vrtx-share-at-component a {
  margin-right: 30px;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../icons/arrow-forward-green.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../icons/icon-chevron-right-green.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../icons/icon-chevron-down-green.svg");
  }
}
//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-neutral--light;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../images/dropdown-black.png") no-repeat
        scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../images/dropdown-black-hover.png");
      }
    }
    .eu-flag > span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
    }
    .eu-flag > img {
      padding-top: 20px;
      float: left;
    }
    .uio-logo {
      margin-left: 160px;
      margin-top: 28px;
      position: absolute;
      > a:hover,
      > a:focus {
        background-color: @color-neutral--light !important;
        box-shadow: none !important;
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      #head {
        height: 165px;
        padding: 14px;
        #header {
          a {
            font-size: 22px;
            font-size: 2.2rem;
            line-height: 25px;
            line-height: 2.5rem;
            img {
              max-width: 55%;
              margin-top: -14px;
            }
            .logo-tekst {
              margin-left: -60px;
              margin-top: 69px;
              position: absolute;
              min-width: 100%;
              span {
                margin-left: -108px;
                font-size: 1.8rem;
                font-size: 18px;
                line-height: 1.6rem;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
    #menu-wrapper {
      .menu,
      .menu li.active {
        background: @color-primary none repeat scroll 0 0;
      }
    }
    #lang-link a {
      display: none !important;
    }
    #footer-wrapper {
      .uio-logo {
        margin-left: 120px;
        margin-top: 30px;
        position: absolute;
        padding-right: 5px;
      }
      .responsible-login .content {
        margin-left: 0px;
      }
    }
  }
  #vrtx-frontpage #main {
    .grid-container:not(.row-last-colored)
      + .grid-container:not(.row-first-colored) {
      border-top: none;
    }
    .grid-container:not(.row-last-colored):not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive)
      + .grid-container:not(.row-first-colored) {
      margin-top: -50px;
    }
    .vrtx-introduction {
      font-size: 2.2rem;
      font-size: 22px;
      line-height: 3.2rem;
      line-height: 32px;
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #7FB619; //(green)
@color-primary--dark : darken(spin(@color-primary, -5), 10%);
@color-secondary : #4B57B4; //(blue)
@color-secondary--dark : darken(spin(@color-secondary, -5), 10%);
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #EAEDEE;
@color-lines : #F0F0F0;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #2b2b2b;
@footer-color : @color-neutral--light;

@font-face {
  font-family: Msreference;
  src: url(../fonts/refsan.ttf);
  font-weight: bold;
}
